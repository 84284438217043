<template>
  <div style="background-color: #fff;padding: 20px;">
    <div class="notice-title h1">{{ detailData.activityName }}</div>
    <div class="h4 time">【举办时间】：{{ detailData.activityDate }}</div>
    <div style="display: flex;justify-content: center;">
      <img :src="detailData.pictureUrl" style="width: 50%;height: auto;object-fit: fill;" />
    </div>
    <div class="notice-content">
      <div class="gaikuang-title">介绍</div>
      <div v-html="detailData.introduction" class="ql-editor"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  components: {},
  data() {
    return {
      notice_id: null,
      detailData: {},
    }
  },
  computed: {
  },
  created(options) {
    console.log('options = ', options)
    console.log('this.$route.query = ', this.$route.query)
    const notice_id = this.$route.query?.id
    if (notice_id) {
      this.notice_id = notice_id
      this.$api.solicitationCloud.fundActivityDetail({ 'id': this.notice_id }).then((res) => {
        const data = res.data.data
        if (data) {
          this.detailData = data;
        }
      })
    }
  },
  mounted() {

  },
  methods: {

  }
}
</script>
<style scoped lang="less">
.notice-title {
  text-align: center;
  margin-top: 35px;
  font-weight: 700;
}

.time {
  text-align: right;
  margin: 10px 0;
  padding-bottom: 30px;
  border-bottom: 2px dashed #cccccc;
}

.notice-content {
  margin-top: 30px;

  .gaikuang-title {
    height: 50px;
    line-height: 50px;
    padding-left: 20px;
    margin: 30px 0;
    border-bottom: 1px solid #cccccc;
  }
}
</style>
